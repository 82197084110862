import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/shared/seo';
import { Header } from '../components/shared/header/header';
import { Article } from '../components/article/article';
import { Footer } from '../components/shared/footer/footer';
import { Container } from '../components/shared/container';
import { Pagination } from '../components/shared/pagination/pagination';
import { Link } from '../components/shared/link';
import { navigate, PageProps } from 'gatsby';
import { ArticlePreview, BlogPageContext } from '../types';

interface BlogData {
  allPrismicArticle: {
    nodes: ArticlePreview[];
  };
}

const BlogPage: React.FC<PageProps<BlogData, BlogPageContext>> = ({
  data,
  pageContext,
}) => {
  const handleNextPage = () => {
    if (pageContext.currentPage < pageContext.totalPageCount) {
      navigate(`/${pageContext.locale}/blog/${pageContext.currentPage + 1}`);
    }
  };

  const handlePrevPage = () => {
    if (pageContext.currentPage > 2) {
      navigate(`/${pageContext.locale}/blog/${pageContext.currentPage - 1}`);
    } else if (pageContext.currentPage === 2) {
      navigate(`/${pageContext.locale}/blog`);
    }
  };

  return (
    <Layout>
      <SEO title="blog" />
      <Header />
      <div className="relative pb-64 mt-36 sm:mt-28 z-10">
        <Container paddingRight="big">
          <h1 className="header-xs text-greyscale-mediumlight -mb-3 sm:-mb-4">
            Articles
          </h1>
        </Container>
        <div className="max-w-body m-auto ">
          <h1 className="header-2xl whitespace-nowrap text-ecit-green mt-0 mb-2 sm:mt-10 sm:mb-13.5">
            Blog
          </h1>
        </div>
        <Container paddingRight="big">
          {data.allPrismicArticle.nodes.map(article => (
            <Link
              key={article.id}
              to={`/${article.uid}`}
              className="block mb-16 pb-1"
            >
              <Article
                date={article.first_publication_date}
                title={article.data.title.text}
                author={{
                  name: article.data.author_name,
                  role: article.data.author_role,
                  image: article.data.author_photo.url,
                }}
                content={
                  article.data.preview?.raw?.length
                    ? [
                        {
                          type: 'content',
                          content: article.data.preview.raw,
                        },
                      ]
                    : []
                }
              />
            </Link>
          ))}
          <Pagination
            onNextClick={handleNextPage}
            onPrevClick={handlePrevPage}
            pagesCount={pageContext.totalPageCount}
            currentPage={pageContext.currentPage}
          />
        </Container>
      </div>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query ($articleIds: [String]) {
    allPrismicArticle(
      filter: { id: { in: $articleIds } }
      sort: { fields: first_publication_date, order: DESC }
    ) {
      nodes {
        data {
          author_photo {
            alt
            url
          }
          author_name
          author_role
          preview {
            raw
          }
          title {
            text
          }
        }
        first_publication_date(formatString: "DD / MM / yyyy")
        uid
        id
      }
    }
  }
`;

export default BlogPage;
