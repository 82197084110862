import React from 'react';
import ChevronLeft from '../../../assets/svg/chevron-left.svg';
import ChevronRight from '../../../assets/svg/chevron-right.svg';

export interface PaginationProps {
  pagesCount: number;
  currentPage: number;
  onPrevClick: () => void;
  onNextClick: () => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  pagesCount,
  currentPage,
  onPrevClick,
  onNextClick,
}) => {
  return (
    <div className="border-2 rounded-3xl px-3.5 py-1.5 bg-transparent text-ecit-blue border-ecit-blue w-min flex items-center justify-between">
      <button onClick={onPrevClick}>
        <ChevronLeft />
      </button>
      <span className="button px-11 whitespace-nowrap">{`${currentPage} / ${pagesCount}`}</span>
      <button onClick={onNextClick}>
        <ChevronRight />
      </button>
    </div>
  );
};
